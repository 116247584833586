<template>
  <task-sheets></task-sheets>
</template>

<script>
import TaskSheets from '../headOfFleet/TaskLists.vue'
export default {
  data () {
    return {

    }
  },
  components: {
    TaskSheets
  }
}
</script>